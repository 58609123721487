// About.js
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import Art0001 from "./images/Art/Art0001.jpeg";
import Art0002 from "./images/Art/Art0002.jpeg";
import Art0003 from "./images/Art/Art0003.jpeg";
import Art0004 from "./images/Art/Art0004.jpeg";
import Art0005 from "./images/Art/Art0005.jpeg";
import Art0006 from "./images/Art/Art0006.jpeg";
import Art0007 from "./images/Art/Art0007.jpeg";
import Art0008 from "./images/Art/Art0008.jpeg";
import Art0009 from "./images/Art/Art0009.jpeg";
import Art0010 from "./images/Art/Art0010.jpeg";
import "./App.css";

const stripePromise = loadStripe(
  "pk_live_51No8gnC2Czp1qk9W12D5NRH8AAxwJpQj7bDMQXdlF5qXRExrj23jNbjNNVHV8xpKvKe0yS1Lk9JramV8KmSJTY1Y00tRDq5prj"
);

const ProductGrid = ({ products, onBuyNow, loadingProductId }) => {
  return (
    <div className="product-grid">
      {products.map((product, index) => (
        <div key={index} className="product-card">
          <img
            src={product.image}
            alt={product.name}
            className="product-image"
          />
          <div className="product-info">
            <div
              style={{
                display: "flex",
                flex: "1 0 0",
                flexDirection: "column",
              }}
            >
              <h2 className="product-name">{product.name}</h2>
              <p className="product-detail">{product.size}</p>
              <p className="product-price">{product.price}</p>
            </div>
            <div>
              {product.status === "On exhibition" ? (
                <h2 className="product-sold">On exhibition</h2>
              ) : product.status === "Sold" ? (
                <h2 className="product-sold">Sold</h2>
              ) : (
                <button
                  onClick={() => onBuyNow(product)}
                  className="buy-now-button"
                  style={
                    loadingProductId === product.id
                      ? { borderBottom: "none", color: "#878787" }
                      : {}
                  }
                >
                  {loadingProductId === product.id ? "Loading..." : "Buy now"}
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

function Shop() {
  const [loadingProductId, setLoadingProductId] = useState(null);

  const products = [
    {
      id: "art_0007",
      name: "Yellow",
      size: "25 × 21cm",
      price: "200€",
      priceInCents: 20000, // Price in cents for Stripe
      image: Art0007,
      status: "Available",
    },
    {
      id: "art_0008",
      name: "Untitled",
      size: "23 × 14cm",
      price: "150€",
      priceInCents: 15000, // Price in cents for Stripe
      image: Art0008,
      status: "Available",
    },
    {
      id: "art_0009",
      name: "Grenada",
      size: "24 × 21cm",
      price: "200€",
      priceInCents: 20000, // Price in cents for Stripe
      image: Art0009,
      status: "Available",
    },
    {
      id: "art_0001",
      name: "Untitled",
      size: "21 × 20cm",
      price: "150€",
      priceInCents: 15000, // Price in cents for Stripe
      image: Art0001,
      status: "Available",
    },
    {
      id: "art_0002",
      name: "Untitled",
      size: "21 × 20cm",
      price: "150€",
      priceInCents: 15000, // Price in cents for Stripe
      image: Art0002,
      status: "Available",
    },
    {
      id: "art_0003",
      name: "Pez Hacha",
      size: "15 × 12cm",
      price: "150€",
      priceInCents: 15000, // Price in cents for Stripe
      image: Art0003,
      status: "Available",
    },
    {
      id: "art_0004",
      name: "Ajman",
      size: "14 × 13cm",
      price: "350€",
      priceInCents: 35000, // Price in cents for Stripe
      image: Art0004,
      status: "On exhibition",
    },
    {
      id: "art_0005",
      name: "Esztergomi",
      size: "17 × 12cm",
      price: "350€",
      priceInCents: 35000, // Price in cents for Stripe
      image: Art0005,
      status: "On exhibition",
    },
    {
      id: "art_0006",
      name: "Serie Le Hoyo",
      size: "13 × 13cm",
      price: "150€",
      priceInCents: 15000, // Price in cents for Stripe
      image: Art0006,
      status: "Available",
    },
    {
      id: "art_0010",
      name: "Republique Rwandaise",
      size: "19 × 16cm",
      price: "350€",
      priceInCents: 35000, // Price in cents for Stripe
      image: Art0010,
      status: "On exhibition",
    },
    // Add more art here
  ];

  const handleBuyNow = async (product) => {
    setLoadingProductId(product.id);
    try {
      const response = await fetch(
        "/.netlify/functions/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product.id,
            priceInCents: product.priceInCents,
          }),
        }
      );
      const session = await response.json();
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (error) {
        console.error("Error:", error);
        setLoadingProductId(null);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingProductId(null);
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Felix Ingla</title>
        <meta
          name="description"
          content="Explore and purchase unique artworks by Felix Ingla. Original pieces and prints available with worldwide shipping. Discover the intersection of art and technology."
        />
      </Helmet>
      <article>
        <p>Shipping worldwide. No returns.</p>
        <div className="App">
          <ProductGrid
            products={products}
            onBuyNow={handleBuyNow}
            loadingProductId={loadingProductId}
          />
        </div>
      </article>
      <br />
      <br />
    </>
  );
}

export default Shop;
